*,
*:before,
*:after {
  -moz-box-sizing: border-box;
       box-sizing: border-box;
}
html {
  height: 100%;
  background: url('/images/assets/bg-partnership.png') no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
}
.alert {
  border-radius: 0;
  margin-top: 10px;
}
.alert .strong {
  text-transform: uppercase;
  font-weight: bold;
  display: block;
}
.alert-danger {
  background: #e74c3c;
  border: 1px solid #e74c3c;
  color: white;
}
.form .title {
  color: #FFF;
  text-align: center;
  font-family: "lato", sans-serif;
  font-weight: bold;
  font-size: 40px;
  letter-spacing: 10px;
  padding-left: 10px;
  background: -webkit-linear-gradient(white, #38495a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 148px;
}
.form .title .thin {
  font-weight: 300;
  font-size: 30px;
  letter-spacing: 2px;
}
.form .title img {
  vertical-align: middle;
  display: block;
  margin: 50px auto;
}
#title span {
  background: -webkit-linear-gradient(white, #38495a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@-webkit-keyframes animStar {
  from {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  to {
    -webkit-transform: translateY(-2000px);
            transform: translateY(-2000px);
  }
}
@-moz-keyframes animStar {
  from {
    -moz-transform: translateY(0px);
         transform: translateY(0px);
  }
  to {
    -moz-transform: translateY(-2000px);
         transform: translateY(-2000px);
  }
}
@keyframes animStar {
  from {
    -webkit-transform: translateY(0px);
       -moz-transform: translateY(0px);
            transform: translateY(0px);
  }
  to {
    -webkit-transform: translateY(-2000px);
       -moz-transform: translateY(-2000px);
            transform: translateY(-2000px);
  }
}
body {
  font-family: 'Open Sans', sans-serif;
  background: none !important;
}
a {
  text-decoration: none;
  color: #1ab188;
  -moz-transition: .5s ease;
  transition: .5s ease;
}
a:hover {
  color: #1a6db1;
}
.container {
  background: none !important;
}
.form {
  margin-top: 3%;
}
.form .aside {
  background: rgba(19, 35, 47, 0.9);
  padding: 0;
  border-radius: 0px;
  border: 1px solid #101010;
  box-shadow: 0 4px 10px 4px rgba(19, 35, 47, 0.3);
}
.form .aside .tab-content {
  padding: 0 40px 20px 40px;
}
.tab-group {
  list-style: none;
  padding: 0;
}
.tab-group:after {
  content: "";
  display: table;
  clear: both;
}
.tab-group li a {
  display: block;
  text-decoration: none;
  padding: 15px;
  background: rgba(160, 179, 176, 0.25);
  color: #a0b3b0;
  font-size: 20px;
  float: left;
  width: 50%;
  text-align: center;
  cursor: pointer;
  -moz-transition: .5s ease;
  transition: .5s ease;
}
.tab-group li a:hover {
  background: #1a6db1;
  color: #ffffff;
}
.tab-group .active a {
  background: #1a6db1;
  color: #ffffff;
}
.tab-content > div {
  display: none;
}
.tab-content > div:first-child {
  display: block;
}
h1 {
  text-align: center;
  color: #ffffff;
  font-size: 24px;
  font-weight: 300;
  margin: 20px 0;
}
label {
  position: absolute;
  -webkit-transform: translateY(6px);
  -moz-transform: translateY(6px);
   -ms-transform: translateY(6px);
       transform: translateY(6px);
  left: 13px;
  color: rgba(255, 255, 255, 0.5);
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
  -webkit-backface-visibility: hidden;
  pointer-events: none;
  font-size: 22px;
}
label .req {
  margin: 2px;
  color: #1ab188;
}
label.active {
  -webkit-transform: translateY(50px);
  -moz-transform: translateY(50px);
   -ms-transform: translateY(50px);
       transform: translateY(50px);
  left: 2px;
  font-size: 14px;
}
label.active .req {
  opacity: 0;
}
label.highlight {
  color: #ffffff;
}
input,
textarea {
  font-size: 22px;
  display: block;
  width: 100%;
  height: 45px;
  padding: 5px 10px;
  background: none;
  background-image: none;
  border: 1px solid #a0a5b3;
  color: #ffffff;
  border-radius: 0;
  -moz-transition: border-color 0.25s ease, box-shadow 0.25s ease;
  transition: border-color 0.25s ease, box-shadow 0.25s ease;
}
input:focus,
textarea:focus {
  outline: 0;
  border-color: #1ab188;
}
textarea {
  border: 2px solid #a0b3b0;
  resize: vertical;
}
.field-wrap {
  position: relative;
  margin-bottom: 30px;
}
.top-row:after {
  content: "";
  display: table;
  clear: both;
}
.top-row > div {
  float: left;
  width: 48%;
  margin-right: 4%;
}
.top-row > div:last-child {
  margin: 0;
}
.button {
  border: 0;
  outline: none;
  border-radius: 0;
  padding: 15px 0;
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .1em;
  background: #1a6db1;
  color: #ffffff;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-appearance: none;
  text-align: center;
}
.button:hover {
  text-decoration: none;
  color: white;
}
.button:hover,
.button:focus {
  background: #4285f4;
  cursor: pointer;
}
.button-block {
  display: block;
  width: 100%;
}
.forgot {
  margin-top: -20px;
  text-align: right;
}
.birth-year {
  border: 1px solid #00aeef;
  color: white;
  background: #1a6db1;
  padding: 5px 15px;
}
.birth-year:hover {
  cursor: pointer;
}
.birth-month {
  border: 1px solid #00aeef;
  color: white;
  background: #1a6db1;
  padding: 5px 15px;
  margin-right: 10px;
}
.birth-month:hover {
  cursor: pointer;
}
.birth-day {
  border: 1px solid #00aeef;
  color: white;
  background: #1a6db1;
  padding: 5px 15px;
  margin-right: 10px;
}
.birth-day:hover {
  cursor: pointer;
}
.modal {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.modal .modal-header {
  color: #fff;
  font-weight: bolder;
  font-size: 22px;
  border-bottom: 1px solid #333;
}
.modal .modal-body {
  color: rgba(255, 255, 255, 0.5);
  font-size: 22px;
}
.modal .modal-body input {
  border: 1px solid #333;
}
.modal .modal-content {
  background: rgba(0, 0, 0, 0.9);
  border: 1px solid #333;
}
.modal .modal-footer {
  border-top: 1px solid #333;
}
.modal .modal-footer button {
  padding: 10px 30px;
}
.modal .modal-footer button#close-register-google {
  background: #1b2735;
}
.link-reset {
  color: white;
  float: left;
  margin-bottom: 10px;
  margin-top: 15px;
}
